import JostNormal from "./items/Jost-400-Book.ttf";
import JostNormalOtf from "./items/Jost-400-Book.otf";

import JostMedium from "./items/Jost-500-Medium.ttf";
import JostMediumOtf from "./items/Jost-500-Medium.otf";

export default {
  JostNormal,
  JostNormalOtf,
  JostMedium,
  JostMediumOtf
};
