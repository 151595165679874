import styled from "styled-components";

export const SmallSubtitlesTypo = styled.div`
  font-size: 1.4rem;
  line-height: 2;
  font-weight: 500;
`;

export const LinkTypo = styled.div`
  font-size: 1.6rem;
  line-height: 2;
  font-weight: 300;
  color: ${ props => props.color };
  transition: color 400ms linear 0s;
  transition-delay: 500ms;
  text-decoration: none;
  cursor: pointer;
`;
export const SmallSubtitlesTypoP = styled(SmallSubtitlesTypo)`
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const LargeHeaderTypo = styled.div`
  font-size: 4rem;
  line-height: 1.4;
  font-weight: 600;

  /* 400px */
  @media (min-width: 25em) {
      font-size: 3.5rem;
    }

  /* 496px */
  @media (min-width: 31em) {
      font-size: 4rem;
    }

  /* 768px */
  @media (min-width: 48em) {
      font-size: 4.5rem;
    }

  /* 1024px */
    @media (min-width: 64em) {
      font-size: 6rem;
    }

  /* 2100px */
    @media (min-width: 131.250em) {
      font-size: 8rem;
    }
`;


export const MediumHeaderTypo = styled.div`
  font-size: 4rem;
  line-height: 1.4;
  font-weight: 500;
`;

export const SmallHeaderTypo = styled.div`
  font-size: 3rem;
  line-height: 1.6;
  font-weight: 500;
`;

export const CardSubtitleTypo = styled(SmallSubtitlesTypo)`
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const BasicParagraphTypo = styled.div`
  font-size: 1.8rem;
  line-height: 2;
  font-weight: 400;
`;

export const SmallestHeader = styled.div`
  font-size: 2.2rem;
  font-weight: 500;
`;

export const MediumParagraphTypo = styled.div`
  font-size: 2.2rem;
  line-height: 2;
  font-weight: 400;
`;

export const ProgressIndicatorTypo = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.5px;
`;

export const TimelineAnimAvatarTypo = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
`;

export const TipTypo = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const NotFound404Typo = styled.div`
  font-size: 40vh;
  font-weight: 400;
  line-height: 1;
`;
