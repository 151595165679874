/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import background from '../images/tlo.png';

function SEO({ description, lang, meta, title, path }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  
  const metaDescription = description || site.siteMetadata.description;
  
  const thisPath = path ? path : '';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title === 'Home' || !title ? site.siteMetadata.title : title+' | '+site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          name: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          name: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          name: `og:type`,
          content: `website`,
        },
        {
          property: `twitter:card`,
          name: `twitter:card`,
          content: `summary`,
        },
        {
          property: `og:url`,
          name: `og:url`,
          content: `https://hioiapp.com`+thisPath,
        },
        {
          property: `og:image`,
          name: `og:image`,
          content: `https://hioiapp.com`+background,
        }
      ].concat(meta)}
    >
    <script type="application/ld+json">{`
      'sameAs' : [
        'https://www.facebook.com/hioiapp/'
      ],
    `}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
