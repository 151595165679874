import styled, { keyframes } from "styled-components";

const jumpAnim = keyframes`
  0% {
    transform: rotate(90deg) translate(10%,0);
  }
  50% {
    transform: rotate(90deg) translate(0,0);
  }
  100% {
    transform: rotate(90deg) translate(10%,0);
  }
`

export const FixedTopLogo = styled.div`
  display: block;
  position: fixed;
  left: 5rem;
  top: 10%;
  z-index: 200;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  /* 600px */
  @media (min-width: 37.5em) {
    left: 10rem;
  }

  /* 1024px */
    @media (min-width: 64em) {
    left: 10rem;
  }

  /* 1500px */
  @media (min-width: 93.750em) {
    left: 50rem;
  }

  svg g rect {
    transition:500ms;
    transition-delay: 500ms;
  }

  svg g path {
    transition:500ms;
    transition-delay: 500ms;
  }

  svg g polygon {
    transition:500ms;
    transition-delay: 500ms;
  }
`;

export const FixedTopContact = styled.div`
  display: block;
  position: fixed;
  right: 5rem;
  top: 10%;
  z-index: 200;
  text-align: right;
  text-decoration: none;
  border-bottom: 2px solid #ffcbc7;

  /* 600px */
  @media (min-width: 37.5em) {
    right: 10rem;
  }

  /* 1500px */
  @media (min-width: 93.750em) {
    right: 30rem;
  }
  @media (min-width: 120em) {
    right: 50rem;
  }
`;

export const FixedScrollDown = styled.div`
  display: none;
  position: absolute;
  width: 20%;
  top: calc(30vh + 10rem);
  transform: rotate(90deg);
  transition: 600ms ease-out;
  transform-origin: 0 0;
  animation: ${jumpAnim} 2s linear 0s infinite;
  z-index: 200;

  /* 600px */
  @media (min-width: 37.5em) {
    left: calc(12rem + (100% - 80rem) * 0.1);
  }

  /* 1500px */
  @media (min-width: 93.750em) {
    left: calc(12rem + (100% - 80rem) * 0.1);
  }

  /* 1920px */
  @media (min-width: 120em) {
    left: calc(14.5rem + (100% - 80rem) * 0.1);
  }

  /* 2300px */
  @media (min-width: 143.750em) {
    left: calc(42rem + (100% - 80rem) * 0.1);
  }

`;
