import styled from "styled-components";

export const SectionBasicStyle = styled.div`
  display: block;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  padding: 3rem 5%;

  /* 700px */
    @media (min-width: 43.750em) {
      padding: 3rem 7rem;
    }

  /* 1024px */
    @media (min-width: 64em) {
      padding: 12rem 20rem;
    }

  /* 1500px */
    @media (min-width: 93.750em) {
      padding: 15rem 15rem;
    }

  /* 1920px */
    @media (min-width: 120em) {
      padding: 20rem 30rem;
    }
`;

export const SectionHeaderStyle = styled(SectionBasicStyle)`
  height: 100vh;
  z-index: 15;
  color: #ffffff;
  overflow: auto;
`;

export const SectionMeetHioiStyle = styled(SectionBasicStyle)`
  z-index: 100;

  /* 1024px */
    @media (min-width: 64em) {
    }

  /* 1500px */
    @media (min-width: 93.750em) {
      margin: 34% 0 0 0;
      padding: 0rem 30rem;
    }
`;


export const SectionFeatureStyle = styled(SectionBasicStyle)`
  background: rgba(255, 255, 255, 0.85);

  /* 1024px */
    @media (min-width: 64em) {
    }

  /* 1500px */
    @media (min-width: 93.750em) {
    }
`;

export const SectionIconsStyle = styled(SectionBasicStyle)`
    background: #F3F4FF;
    overflow: hidden;

    /* 1024px */
      @media (min-width: 64em) {
      }

    /* 1500px */
      @media (min-width: 93.750em) {
      }
`;


export const SectionSubscribeStyle = styled(SectionBasicStyle)`
  background: linear-gradient(101deg,#b0c5ff 45%,#e9efff);
  display: inline-block;
  padding: 10rem 5%;

  /* 700px */
    @media (min-width: 43.750em) {
      padding: 10rem 7rem;
      overflow: hidden;
    }

  /* 1024px */
    @media (min-width: 64em) {
    }

  /* 1920px */
    @media (min-width: 120em) {
      padding: 25rem 7rem;
    }
`;

export const SectionFooterStyle = styled(SectionBasicStyle)`
  background: #3241ce;
  overflow: auto;
  padding: 10rem 5%;

  /* 700px */
    @media (min-width: 43.750em) {
      padding: 3rem 7rem;
    }

  /* 1024px */
  @media (min-width: 64em) {
    padding: 6rem 20rem;
  }

  /* 1500px */
  @media (min-width: 93.750em) {
    padding: 10rem 30rem;
  }
`;
