import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: block;
  position: absolute;
  width: calc(100% - 10rem);
  left: 5rem;
  bottom: 5rem;
  float: left;

  /* 1024px */
  @media (min-width: 64em) {
    bottom: 10%;
    left: 12rem;
    width: calc(100% - 24rem);
    padding: 12rem 12rem 0rem 10rem;
  }
  

`;



export const HeaderLine = styled.div`
  display: none;
  position: relative;
  left: 0;
  width: 3rem;
  margin-right: calc(20% - 3rem);
  margin-bottom: 0;
  top: 0;
  height: 2px;
  background: #ffffff;
  vertical-align: top;

  /* 1500px */
  @media (min-width: 93.75em) {
      display: inline-block;
    }
`;

export const HeaderMainHeader = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 280px;
  vertical-align: bottom;

  /* 1500px */
  @media (min-width: 93.75em) {
    width: 60%;
  }
`;

export const HeaderWhatIs = styled.div`
  display: none;
  position: relative;
  width: 20%;
  right: 0;
  bottom: 16%;
  line-height: 1.4;
  transition: 600ms ease-out;
  text-align: right;
  vertical-align: bottom;

  /* 1500px */
  @media (min-width: 93.75em) {
      display: inline-block;
    }
`;

export const HeaderEmail = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-top: 5rem;
  font-size: 1rem;

  /* 1500px */
  @media (min-width: 93.75em) {
      margin-top: 10rem;
      width: 50rem;
    }
`;
