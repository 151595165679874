import styled from "styled-components";

export default styled.div`
  display: block;
  position: relative;
  width:100%;
  background: linear-gradient(0deg,#6a91ff 45%,#3241ce);

  /* 600px */
  @media (min-width: 37.5em) {
    width: 100%;
  }

  /* 1500px */
  @media (min-width: 93.75em) {
    width:calc(100% - 10rem);
    margin: auto;
  }


  /* 1920px */
  @media (min-width: 120em) {
    width:calc(100% - 15rem);
  }

  /* 2300px */
  @media (min-width: 143.750em) {
    width:calc(100% - 40rem);
  }
  `;
